import "./partnerTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { MEASwimlane } from "~swimlaneViews/meaSwimlane";
import { PlaylistMixedSwimlane } from "~swimlaneViews/playlistMixedSwimlane";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, DOMHelper, IListComponent, Listenable, ListenableSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { MEABanner } from "../../models/meaBanner";
import { Partner } from "../../models/partner";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { MEABannerView } from "../../views/MEABannerView";

export class PartnerTab extends View {
  listComponent?: IListComponent<ItemCollection | MEABanner>;
  private _scrollIndexUnregister?: () => void;
  private _source: Partner;
  private _source$?: Listenable<(ItemCollection | MEABanner)[]> = undefined;
  private _background?: DynamicBackground;
  private _sendPianoAnalytics?: () => void;

  /**
   * List of allowed slider types on PartnerTab
   */
  private readonly _validSwimlaneTypes = [
    "mise_en_avant",
    "playlist_video",
    "playlist_program",
    "playlist_mixed",
    "playlist_collection",
    "live",
  ];

  constructor(source: Partner) {
    super(DOMHelper.createDivWithParent(null, "PartnerTab"));

    this._source = source;
    this._fetchSource();
  }

  onShown() {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          // Here use it to create the UI
          Log.api.log("[DETAILED PARTNER] next !", value);
          this._source = value[0];
          this._onSourceReady(value[0].items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[DETAILED PARTNER] Error !", error);
        },
        () => {}
      );
  }

  private _onSourceReady(sourceReference: ItemCollection[]) {
    const source: (ItemCollection | MEABanner)[] = [];

    // adding MEA first
    let firstSwimlanePushedInSourceAsMEAorMEABanner = false;
    // (sourceReference[0].type as any) = "playlist_mixed"; // uncomment to test the case: first swimlane is not a "mise_en_avant"
    if (sourceReference[0]?.type === "mise_en_avant" && sourceReference[0].items.length) {
      //sourceReference[0].items = [sourceReference[0].items[0]]; // uncomment to test the case: "mise_ena-avant have only one item"
      const meaImmersiveItem = sourceReference[0].items[0];
      firstSwimlanePushedInSourceAsMEAorMEABanner = true;
      //Add key to identify MEA Banner origin when calling analytic functions
      meaImmersiveItem.extras = meaImmersiveItem.extras ?? {};
      meaImmersiveItem.extras.isMeaImmersive = true;

      source.push(new MEABanner(meaImmersiveItem));
      sourceReference[0].items.shift();
      if (sourceReference[0].items.length > 0) {
        source.push(sourceReference[0]);
      }
    }

    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      staticRowTypes: this._validSwimlaneTypes,
      staticSource: require("~images/background/partner_background.jpg"),
    });

    DOMHelper.createImg(this.rootElement, "PartnerLogo", null, this._source.getLogoImgUrl(), "");
    sourceReference.forEach((sl, index) => {
      //MEA already pushed in source temp
      if (firstSwimlanePushedInSourceAsMEAorMEABanner && index === 0) {
        return;
      }
      if (this._validSwimlaneTypes.includes(sl.type)) {
        source.push(sl);
      }
    });

    this._source$ = new Listenable(source);
    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentPartnerTabSwimlane");

    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: contentRoot,
        modelSource: new ListenableSource(this._source$, true),
        viewFactory: model => {
          if (model instanceof MEABanner) {
            return new MEABannerView(model.item, false);
          } else if (model.type == "mise_en_avant") {
            return new MEASwimlane(model);
          } else if (model.type === "playlist_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type === "playlist_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type === "playlist_mixed") {
            return new PlaylistMixedSwimlane(model);
          } else if (model.type === "playlist_collection") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type === "live") {
            return new PlaylistVideoSwimlane(model);
          } else {
            //default
            return new PlaylistMixedSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );
    this._background.setItemCollectionList(this.listComponent);

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });

    this._sendPianoAnalytics = this.listComponent.focusedView$.didChange(newView => {
      if (newView instanceof MEABannerView) {
        const item = newView.getItem();
        sendPianoAnalytic(
          "publisher.impression",
          {},
          {
            feature: "bloc_" + newView.rootElement.innerText,
            zone: "mea_immersive",
            content_type: item.type,
            content_title: item.title,
          }
        );
      } else if (newView instanceof MEASwimlane) {
        sendPianoAnalytic("publisher.impression", {}, { feature: "bloc_mea_3X4", zone: "mea" });
      }
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._scrollIndexUnregister?.();
  };
}
